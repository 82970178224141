import { forwardRef } from 'react';

const NavLink = forwardRef(({ href, clickHandler, svg, text, className = 'flex cursor-pointer items-center gap-3 rounded-md py-3 px-3 text-sm text-white transition-colors duration-200 hover:bg-gray-500/10', description, heading, status, ...rest }, ref) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (clickHandler) clickHandler();
    if (href) window.open(href, '_blank');
  };

  const renderBadge = (status) => {
    switch (status) {
      case 'comingSoon':
        return (
          <div className="rounded-md bg-[#E3A912] px-1 pt-[1px] text-[10px] text-white">
            Coming Soon
          </div>
        );
      case 'new':
        return (
          <div className="rounded-md bg-[#177755] px-1 pt-[1px] text-[10px] text-white">New</div>
        );
      case 'pro':
        return (
          <div className="rounded-md bg-[#177755] px-1 pt-[1px] text-[10px] text-white">PRO</div>
        );
      default:
        return null;
    }
  };

  return (
    <a className={className} onClick={handleClick} ref={ref} {...rest}>
      {svg && svg()}
      {heading ? (
        heading
      ) : (
        <div className="ml-1">
          <div className="flex items-center">
            <h3 className="mr-1 text-sm leading-none">{text}</h3>
            {status && renderBadge(status)}
          </div>
          {description && <h4 className="text-xs text-slate-400">{description}</h4>}
        </div>
      )}
    </a>
  );
});

export default NavLink;
