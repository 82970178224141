import React, { useEffect, useRef } from 'react';
import { cn, cardStyle } from '~/utils/';
import { setCookieValue, showPricing } from '~/components/helper/global';
import {
  usePopup,
  usePricingPopup,
  subscriptionType,
  useCustomData,
} from '~/components/helper/store';
import store from '~/store';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Button } from '~/components/ui';
import { getModelName } from '../helper/modelName.js';
import { toast } from 'react-toastify';

type ModelSelectorProps = {
  id?: string;
  title?: string;
  value: string;
  disabled?: boolean;
  setValue: (value: string) => void;
  availableValues: string[];
  showAbove?: boolean;
  showLabel?: boolean;
  containerClassName?: string;
  subContainerClassName?: string;
  className?: string;
  endpoint?: string;
};

const SCROLLBAR_SIZE = 10;

function ModelSelector({
  value,
  setValue,
  title = 'Model',
  disabled,
  availableValues,
  showAbove = false,
  showLabel = true,
  containerClassName,
  className,
  endpoint,
}: ModelSelectorProps) {
  const sState = usePopup((state) => state);
  const proState = subscriptionType((state) => state);
  const conversation = useRecoilValue(store.conversation);
  const [showPopover, setShowPopover] = useRecoilState(store.showPopover);
  const [showOpenAiModels, setShowOpenAiModels] = useRecoilState(store.showOpenAiModels);
  const pState = usePricingPopup((state) => state);
  const selectorRef = useRef<HTMLDivElement | null>(null);
  const { hasFile, imagesPreview, imageUrls } = useCustomData((state) => state);
  const handleClick = () => {
    setShowOpenAiModels(!showOpenAiModels);
    setShowPopover(false);
  };

  const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
  const handleOptionClick = (option: string) => {
    if ((hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0) && option !== 'gpt-4o') {
      setShowOpenAiModels(false);
      const formattedModelName = getModelName(option);
      toast.dismiss();
      toast.error(`${formattedModelName} does not support images`);
      return;
    }

    sState.setMixPanelTrack(option);
    sState.setMixPanelClickLocation('chat-page');
    setCookieValue('mixPanelTrack', option);
    setCookieValue('mixPanelClickLocation', 'chat-page');

    if (option.includes('gpt-4') && option !== 'gpt-4o' && option !== 'gpt-4o-mini' ) {
      if (
        (proState.isPro && option == openai_gpt4_model) ||
        (proState.isProMax && option == 'gpt-4-pro-max')
      ) {
        setValue(option);
      } else {
        showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      }
    } else {
      setValue(option);
    }

    setShowOpenAiModels(false);
  };

  useEffect(() => {
    const forUserInterval = setInterval(() => {
      if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
        const subscriptionType = AIPRO_USER.subscription_type;

        if (subscriptionType) {
          if (
            subscriptionType.toString().toLowerCase() !== 'basic' &&
            subscriptionType !== null &&
            subscriptionType.toString() !== ''
          ) {
            proState.setPro(true);
          }

          if (subscriptionType.toString().toLowerCase() === 'promax') {
            proState.setIsProMax(true);
          }

          if (subscriptionType.toString().toLowerCase() === 'enterprise') {
            proState.setIsEnterprise(true);
          }
        }
        clearInterval(forUserInterval);
      }
    }, 300);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (selectorRef.current && !selectorRef.current.contains(e.target as Node)) {
        setShowOpenAiModels(false);
      }
    };

    if (showOpenAiModels) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showOpenAiModels]);

  return (
    <div className="model-selector relative" ref={selectorRef}>
      <Button
        type="button"
        className={cn(
          cardStyle,
          'z-50 flex h-10 w-10 min-w-4 flex-none items-center justify-center p-0 focus:ring-0 focus:ring-offset-0',
        )}
        onClick={handleClick}
        aria-label="Model selector"
      >
        <img
          src="/assets/ai-pro-logo-only.png"
          alt="Ai logo"
          width="33"
          height="20"
          className="h-auto w-7 hidden dark:block invert"
        />
        <img
          src="/assets/ai-pro-logo-only.png"
          alt="Ai logo"
          width="33"
          height="20"
          className="h-auto w-7 block dark:hidden"
        />
      </Button>
      <div
        className={cn(
          'model-selector-options absolute bottom-12 left-1/2 flex w-full min-w-max -translate-x-1/2 flex-col items-center gap-2',
          showOpenAiModels ? '' : 'pointer-events-none',
        )}
      >
        {availableValues.filter(option => option !== openai_gpt4_model).map((option, i) =>
            proState.isEnterprise == false || 
            (proState.isEnterprise && option != openai_gpt4_model  && option != 'gpt-4-pro-max') ? (
            <div
              key={i}
              onClick={() => handleOptionClick(option)}
              className={cn(
                '123 model-selector-option btn-neutral border-px flex w-full min-w-[190px] cursor-pointer items-center justify-between gap-3 rounded-md border-solid px-4 py-2 text-sm font-medium transition-opacity duration-100 ease-linear [&.selected]:border-[#2d9ee0]',
                proState.isPro == false &&
                  (option == openai_gpt4_model || option == 'gpt-4-pro-max')
                  ? 'disabled'
                  : '',
                conversation?.model === option ? 'selected' : '',
                showOpenAiModels
                  ? 'opacity-100 [&.disabled]:opacity-85'
                  : 'pointer-events-none opacity-0',
                (hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0) &&
                  option !== 'gpt-4o'
                  ? 'cursor-not-allowed  focus:ring-0 focus:ring-offset-0'
                  : '',
              )}
              style={{
                transitionDelay: `${(showOpenAiModels ? availableValues.length - i : i) * 25}ms`,
              }}
              role="option"
              aria-selected={conversation?.model === option}
              aria-label={getModelName(option)}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleOptionClick(option);
                }
              }}
            >
              <span
                className={
                  (hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0) &&
                  option !== 'gpt-4o'
                    ? 'opacity-50'
                    : ''
                }
              >
                {getModelName(option)}
              </span>
              {proState.isPro == false && option == openai_gpt4_model ? (
                <span
                  className={cn(
                    'gptpro ml-2 rounded-md bg-green-500 px-1 py-0.5 text-xs text-white',
                    hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0
                      ? 'opacity-50'
                      : '',
                  )}
                  role="contentinfo"
                  aria-label="PRO"
                >
                  PRO
                </span>
              ) : null}
              {proState.isProMax == false && option == 'gpt-4-pro-max' ? (
                <span
                  className={cn(
                    'gptpro ml-2 rounded-md bg-green-600 px-1 py-0.5 text-xs text-white',
                    hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0
                      ? 'opacity-50'
                      : '',
                  )}
                  role="contentinfo"
                  aria-label="PRO MAX"
                >
                  PRO MAX
                </span>
              ) : null}
            </div>
          ) : null,
        )}
      </div>
    </div>
  );
}

export default ModelSelector;
