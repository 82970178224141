import React, { useState, useEffect } from 'react';
import { IoIosLock } from 'react-icons/io';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { usePricingPopup, usePopup } from '~/components/helper/store';
import { cookie } from '~/utils';
import global from '~/utils/global';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeEndpointState } from '~/store/endpoints';
import { setCookieValue } from '~/components/helper/global';
import { useSetOptions } from '~/hooks';

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: (index) => ({
    y: 0,
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 0.3,
      delay: index * 0.1
    }
  })
};

const ChatEndpoints = ({ EactiveEndpoint, endpoint, title, handleEndpointClick, isBeta = false, isNew = false, isDisabled = false }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const userPlan = AIPRO_USER.subscription_type || {};
  const activeEndpoint = useRecoilValue(activeEndpointState);
  const setActiveEndpoint = useSetRecoilState(activeEndpointState);
  const pState = usePricingPopup((state) => state);
  const sState = usePopup((state) => state);
  const { setOption } = useSetOptions();
  // console.log("activeEndpoint:", EactiveEndpoint)
  const checkPlanClick = () => {

    if (!isDisabled) {
      const setEndpoint = setOption('endpoint');
      if (typeof setEndpoint === 'function') {
        setEndpoint(endpoint);
        console.log("setEndpoint", endpoint)
      }
      setActiveEndpoint(endpoint);
    }

    if (isDisabled) {
      if ((endpoint == 'dallE' || endpoint == 'claude' || endpoint == 'Llama' || endpoint == 'gemini' || endpoint == 'Opensource') && (!userPlan)) {

        if (
          cookie.getValue('flow') === 'chatapp' &&
          cookie.getValue('n_flow') === 'chatapp'
        ) {
          if (window.mixpanel) {
            window.mixpanel.track("endpoint-tabs-chatpro-chatapp", {
              "mixPanelTrack": endpoint,
              "mixPanelClickLocation": 'endpoint-tabs-chatpro-chatapp',
            });
          }
        } else {
          sState.setMixPanelTrack(endpoint);
          sState.setMixPanelClickLocation('endpoint-tabs-chatpro-modal');
          setCookieValue('mixPanelTrack', endpoint, '.ai-pro.org');
          setCookieValue('mixPanelClickLocation', 'endpoint-tabs-chatpro-modal', '.ai-pro.org');
        }
        global.showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      } else if ((endpoint == 'gemini') && (userPlan == 'BASIC')) {
        if (window.mixpanel) {
            window.mixpanel.track("endpoint-tabs-chatpro-redirect", {
                "mixPanelTrack": endpoint,
                "mixPanelClickLocation": 'endpoint-tabs-chatpro-redirect',
            });
        }
        const ppg = cookie.getValue('ppg') || 59;
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
      } else if ((endpoint == 'dallE' || endpoint == 'claude' || endpoint == 'Opensource') && (userPlan == 'PRO')) {
        if (window.mixpanel) {
          window.mixpanel.track("endpoint-tabs-chatpro-redirect", {
            "mixPanelTrack": endpoint,
            "mixPanelClickLocation": 'endpoint-tabs-chatpro-redirect',
          });
        }
        const ppg = cookie.getValue('ppg') || 59;
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
      } else {
        if (
          cookie.getValue('flow') === 'chatapp' &&
          cookie.getValue('n_flow') === 'chatapp'
        ) {
          if (window.mixpanel) {
            window.mixpanel.track("endpoint-tabs-chatpro-chatapp", {
              "mixPanelTrack": endpoint,
              "mixPanelClickLocation": 'endpoint-tabs-chatpro-chatapp',
            });
          }
        } else {
          sState.setMixPanelTrack(endpoint);
          sState.setMixPanelClickLocation('endpoint-tabs-chatpro-modal');
          setCookieValue('mixPanelTrack', endpoint, '.ai-pro.org');
          setCookieValue('mixPanelClickLocation', 'endpoint-tabs-chatpro-modal', '.ai-pro.org');  
        }
        global.showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      }
    } else {
      handleEndpointClick(endpoint)
      
    }
  };

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    isSmallScreen ? (
      <button
        type="button"
        className={cn(
          'ios-2-cells z-10 relative w-full text-left px-4 py-2 font-bold dark:text-white capitalize',
          { 'bg-blue-200 dark:bg-[#2e4665] text-dark dark:text-white rounded-xl': endpoint === EactiveEndpoint }
        )}
        onMouseDown={checkPlanClick}
        tabIndex={0}
        role="button"
        aria-label={endpoint}
      >
        <div className="activetitl text-nowrap text-left capitalize inline pr-2 align-sub">
          {
            endpoint === 'openAI' 
            ? 'GPTs' 
            : endpoint === 'Opensource' 
            ? 'More LLMs' 
            : endpoint === 'dallE' 
            ? 'DALL·E' 
            : endpoint
          }
          </div>
        {isDisabled && (
          <IoIosLock className='inline text-[18px] text-gray-500'/>
        )}
        {isBeta && (
          <span className="py-0.25 ml-1 rounded bg-blue-200 px-1 py-[2px] text-[10px] text-[#4559A4]">
            Beta
          </span>
        )}
        {isNew && (
          <span className="py-0.25 ml-1 rounded bg-green-500 px-1 py-[2px] text-[10px] text-white">
            New
          </span>
        )}
      </button>
    ) : ( 
      <>
      <motion.button
        type="button"
        className={cn(
          'ios-2-cells flex items-center rounded-t-lg transform skew-x-12 origin-bottom-right',
          EactiveEndpoint === endpoint
            ? cn(
                'ml-[-2px] pl-8 pr-5 py-4 bg-white dark:bg-[#1a1d22] text-[#4082D7] font-bold dark:text-blue-400 cursor-default border-r-2 border-t-2 border-gray-100 dark:border-[#212429] dark:border-[#212429] border-x-2',
                !isDisabled && 'activemobile'
              )
            : 'border-x-2 px-5 py-4 shadow-inner border-t-2 border-x-2 border-gray-100 dark:border-[#212429] bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-gray-800 dark:text-white/90 hover:dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-opacity-[0.2] cursor-pointer mb-[3px]',
        )}
        onClick={checkPlanClick}
        tabIndex={0}
        role="button"
        aria-label={endpoint}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            checkPlanClick();
          }
        }}
        variants={itemVariants}
      >
        {isDisabled && (
          <IoIosLock className='mr-[1px] mb-[2px] inline text-[18px] text-gray-500 transform -skew-x-12'/>
        )}
        <div className="activetitl tabsendpoint text-nowrap text-left transform -skew-x-12 capitalize text-nowrap font-bold">
          {
            endpoint === 'openAI' 
            ? 'GPTs' 
            : endpoint === 'Opensource' 
            ? 'More LLMs' 
            : endpoint === 'dallE' 
            ? 'DALL·E' 
            : endpoint
          }
        </div>
        {isBeta && (
          <span className="py-0.25 ml-1 rounded bg-blue-200 px-1 text-[10px] text-[#4559A4] transform -skew-x-12">
            Beta
          </span>
        )}
        {isNew && (
          <span className="py-0.25 ml-1 rounded bg-green-500 px-1 text-[10px] text-white transform -skew-x-12">
            New
          </span>
        )}
      </motion.button>
      </>
    )
  );
};

export default ChatEndpoints;
