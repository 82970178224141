import { useState, useRef, useEffect } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useUpdateConversationMutation } from 'librechat-data-provider';
import RenameButton from './RenameButton';
import DeleteButton from './DeleteButton';
import store from '~/store';
import { useParams } from 'react-router-dom';
import { RiMessage2Line } from 'react-icons/ri';
import { cn } from '~/utils';

export default function Conversation({ conversation, retainView }) {
  const [currentConversation, setCurrentConversation] = useRecoilState(store.conversation);
  const setSubmission = useSetRecoilState(store.submission);
  const { refreshConversations } = store.useConversations();
  const { switchToConversation } = store.useConversation();
  const updateConvoMutation = useUpdateConversationMutation(currentConversation?.conversationId);
  const [renaming, setRenaming] = useState(false);
  const inputRef = useRef(null);
  const { conversationId, title, messages } = conversation;
  const [titleInput, setTitleInput] = useState(title);
  const params = useParams();
  const messagesTree = useRecoilValue(store.messagesTree);

  const clickHandler = async () => {
    const msgsChecker = messages.length > 0 ? (messagesTree?.length ?? 0) > 0 : true; // temp checker

    if (currentConversation?.conversationId === conversationId && msgsChecker) {
      return;
    }

    // stop existing submission
    setSubmission(null);

    // set document title
    // document.title = title;

    let title = 'Chatbot Pro |';
    let app_name = 'AI-Pro.org';
    if(import.meta.env.VITE_APP_TITLE) title = `${import.meta.env.VITE_APP_TITLE}`;
    document.title = `${title} ${app_name}`;

    // set conversation to the new conversation
    if (conversation?.endpoint === 'gptPlugins') {
      const lastSelectedTools = JSON.parse(localStorage.getItem('lastSelectedTools')) || [];
      switchToConversation({ ...conversation, tools: lastSelectedTools });
    } else {
      switchToConversation(conversation);
    }
  };

  const renameHandler = (e) => {
    e.preventDefault();
    setTitleInput(title);
    setRenaming(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 25);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    setRenaming(false);
  };

  const onRename = (e) => {
    e.preventDefault();
    setRenaming(false);
    if (titleInput.trim() === title || !titleInput.trim()) {
      return;
    }
    updateConvoMutation.mutate({ conversationId, title: titleInput.trim() });
  };

  useEffect(() => {
    if (updateConvoMutation.isSuccess) {
      refreshConversations();
      if (conversationId == currentConversation?.conversationId) {
        setCurrentConversation((prevState) => ({
          ...prevState,
          title: titleInput,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateConvoMutation.isSuccess]);

  useEffect(() => {
    // check if user loads specific convo
    if (params.conversationId === conversationId) {
      clickHandler();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onRename(e);
    }
  };

  const onBlur = () => {
    setTimeout(() => {
      setRenaming(false);
    }, 500);
  };

  return (
    <div
      data-testid="convo-item"
      onClick={() => clickHandler()}
      className={cn(
        'conversation-link group relative flex cursor-pointer items-center gap-3 break-all rounded-md px-3 py-3',
        {
          'active animate-flash pr-14': currentConversation?.conversationId === conversationId,
        },
      )}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          clickHandler();
        }
      }}
      aria-label="conversation link"
    >
      <RiMessage2Line size={16} />
      <div
        className="relative max-h-5 w-32 flex-1 overflow-hidden text-ellipsis whitespace-nowrap break-all"
        aria-label="conversation title"
      >
        {renaming === true ? (
          <input
            ref={inputRef}
            type="text"
            className="m-0 mr-0 w-full border border-blue-500 bg-transparent p-0 text-sm leading-tight outline-none"
            value={titleInput}
            onChange={(e) => setTitleInput(e.target.value)}
            onBlur={onBlur}
            onKeyDown={handleKeyDown}
          />
        ) : (
          title
        )}
      </div>
      {currentConversation?.conversationId === conversationId && (
        <div className="conversation-action-button absolute right-1 z-10 flex">
          <RenameButton
            conversationId={conversationId}
            renaming={renaming}
            renameHandler={renameHandler}
            onRename={onRename}
          />
          <DeleteButton
            conversationId={conversationId}
            renaming={renaming}
            cancelHandler={cancelHandler}
            retainView={retainView}
          />
        </div>
      )}
    </div>
  );
}
