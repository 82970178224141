// useDocumentTitle.js
import { useEffect } from 'react';

function useDocumentTitle(title: string) {
  useEffect(() => {
    let title = 'Chatbot Pro |';
    let app_name = 'AI-PRO';
    if(import.meta.env.VITE_APP_TITLE) title = `${import.meta.env.VITE_APP_TITLE}`;
    if(import.meta.env.VITE_APP_NAME) app_name = `${import.meta.env.VITE_APP_NAME}`;
    document.title = `${title} ${app_name}`;
  }, [title]);
}

export default useDocumentTitle;
