import React, { useEffect, useContext, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import SubmitButton from './SubmitButton';
import OptionsBar from './OptionsBar';
// import { EndpointMenu } from './EndpointMenu';
import Footer from './Footer';
import { useMessageHandler, ThemeContext } from '~/hooks';
import { cn, cookie } from '~/utils';
import global from '~/utils/global';
import store from '~/store';
import Microphone from './Microphone';
import {
  usePopup,
  subscriptionType,
  usePricingPopup,
  useCustomData,
} from '~/components/helper/store';
import { TypeAnimation } from 'react-type-animation';
import FileUpload from './FileUpload';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { getModelName } from '../helper/modelName';
import { toast } from 'react-toastify';
import { setCookieValue } from '~/components/helper/global';
import { motion } from 'framer-motion';
import { useChatContext } from '~/hooks/ChatContext';

const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
const upgradeUrl = import.meta.env.VITE_UPGRADE_URL || 'https://start.ai-pro.org/upgrade';

export default function TextChat({ isSearchView = false }) {
  const { ask, isSubmitting, handleStopGenerating, latestMessage, endpointsConfig } =
    useMessageHandler();
  // const conversation = useRecoilValue(store.conversation);
  const [conversation, setConversation] = useRecoilState(store.conversation);
  const setShowBingToneSetting = useSetRecoilState(store.showBingToneSetting);
  const [text, setText] = useRecoilState(store.text);
  const { theme } = useContext(ThemeContext);
  const isComposing = useRef(false);
  const inputRef = useRef(null);
  const proState = subscriptionType((state) => state);
  const [endpoint, setEndpoint] = useState('');
  const [model, setModel] = useState('');

  const pState = usePricingPopup((state) => state);
  const messagesTree = useRecoilValue(store.messagesTree);
  const { showPoweredBy, setShowPoweredBy } = useChatContext();

  // const [imagesPreview, setImagesPreview] = useState([]);
  // const [imageUrls, setImageUrls] = useState([]);
  const { hasFile, imagesPreview, imageUrls, setImagesPreview, setImageUrls } = useCustomData(
    (state) => state,
  );

  // TODO: do we need this?
  const disabled = false;

  const isNotAppendable = latestMessage?.unfinished & !isSubmitting || latestMessage?.error;
  const { conversationId, jailbreak } = conversation || {};

  // auto focus to input, when enter a conversation.
  useEffect(() => {
    if (!conversationId) {
      return;
    }

    // Prevents Settings from not showing on new conversation, also prevents showing toneStyle change without jailbreak
    if (conversationId === 'new' || !jailbreak) {
      setShowBingToneSetting(false);
    }

    if (conversationId !== 'search') {
      inputRef.current?.focus();
    }
    // setShowBingToneSetting is a recoil setter, so it doesn't need to be in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, jailbreak]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [isSubmitting]);

  useEffect(() => {
    if (conversation) {
      const endpointValue = conversation?.endpoint ?? 'openAI';
      if (!conversation?.endpoint) {
        setConversation({
          ...conversation,
          endpoint: endpointValue
        });
      }
      setEndpoint(endpointValue);
      setModel(conversation?.model);
    }
  }, [conversation]);

  const clickContinue = () => {
    let mixPanelTrack = cookie.getValue('mixPanelTrack');
    let mixPanelClickLocation = cookie.getValue('mixPanelClickLocation');

    if (typeof pState.mixPanelTrack !== 'undefined' && pState.mixPanelTrack != '') {
      mixPanelTrack = pState.mixPanelTrack;
    }

    if (typeof pState.mixPanelClickLocation !== 'undefined' && pState.mixPanelClickLocation != '') {
      mixPanelClickLocation = pState.mixPanelClickLocation;
    }

    if (typeof mixpanel != 'undefined') {
      mixpanel.track('chatpro-upgrade-modal', {
        upgrade: mixPanelTrack,
        'click-location': mixPanelClickLocation,
      });
    }

    if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
      const subscriptionType = AIPRO_USER.subscription_type;
      const payment_interval = AIPRO_USER.payment_interval;

      proState.setType(subscriptionType);
      proState.setPaymentInterval(payment_interval);
    }
  };

  const submitMessage = async () => {
    if (model !== 'gpt-4o') {
      if (hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0) {
        const formattedModelName = getModelName(model);
        toast.dismiss();
        toast.error(`${formattedModelName} does not support images`);
        return;
      }
    }

    if (imagesPreview?.length !== imageUrls?.length) {
      return;
    }

    setCookieValue('mixPanelTrack', model);

    const availableModels = AIPRO_USER.ai_models;
    
    if (!availableModels) {
      console.error("availableModels is undefined or null");
      return;
    }
    console.log("availableModels", availableModels)
    const isNotAvailableModel = !availableModels.includes(model);
    if(isNotAvailableModel) {
      clickContinue();
      global.showPricing(() => {
        pState.setShowPricingPopup(true);
      });
      return;
    }

    if (typeof IS_FREE_USER !== 'undefined' && IS_FREE_USER) {
      // moved to backend
    } else {
      btutil_getChatUsage('chatbotpro');
      let maxTokens = btutilCommon_getCookie('mucnxwlyxt');
      if (maxTokens !== undefined && maxTokens !== '') {
        return;
      }
    }

    ask({ text, images: imageUrls, imagesPreview });
    setText('');
    setImageUrls([]);
    setImagesPreview([]);

    const uploadImage = document.getElementById('upload-image');
    if (uploadImage) {
      uploadImage.value = '';
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isSubmitting) {
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
    }

    if (e.key === 'Enter' && !e.shiftKey && !isComposing?.current) {
      submitMessage();
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 8 && e.target.value.trim() === '') {
      setText(e.target.value);
    }

    if (e.key === 'Enter' && e.shiftKey) {
      return console.log('Enter + Shift');
    }

    if (isSubmitting) {
      return;
    }
  };

  const handleCompositionStart = () => {
    isComposing.current = true;
  };

  const handleCompositionEnd = () => {
    isComposing.current = false;
  };

  const changeHandler = (e) => {
    const { value } = e.target;

    setText(value);
  };

  const getPlaceholderText = () => {
    if (isSearchView) {
      return 'Click a message title to open its conversation.';
    }

    if (disabled) {
      return 'Choose another model or customize GPT again';
    }

    if (isNotAppendable) {
      return 'Edit your message or Regenerate.';
    }

    return 'Enter a message';
  };

  if (isSearchView) {
    return <></>;
  }

  let isDark = theme === 'dark';

  if (theme === 'system') {
    isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  const showFileUpload = conversation && conversation.endpoint === 'openAI' && conversation.model === 'gpt-4o';
  // console.log("conversation.endpoint:", conversation.endpoint);
  // console.log("conversation.model:", conversation.model);

  const handleTypeAnimationComplete = () => {
    setShowPoweredBy(true);
  };

  return (
    <>
      <div className='bottom-[6rem] left-0 w-full sm:bg-gradient-to-b absolute'>
        {!messagesTree?.length && (
          <motion.div
            initial={{ y: window.innerWidth >= 768 ? 80 : 50 }}
            animate={{ y: 0 }}
            transition={{ type: 'spring', stiffness: 300 }}
            className='flex-grow stretch z-[60] mx-auto text-center md:text-left flex w-full max-w-full flex-col md:flex-row items-center gap-3 px-2.5 last:mb-2 lg:max-w-2xl lg:p-0 xl:max-w-3xl space-x-2 sm:text-center text-lg sm:text-xl lg:text-2xl dark:text-white pt-44 md:mt-auto h-screen md:h-full justify-center md:justify-start'
          >
            <div className='flex items-center w-16 h-16 sm:w-14 sm:h-14 flex-shrink-0'>
              <img
                src="/assets/ai-pro-logo-only.png"
                alt="AI Pro"
                className="block dark:hidden"
              />
              <img
                src="/assets/ai-pro-logo-only.png"
                alt="AI Pro"
                className="hidden dark:block p-2 invert"
              />
            </div>

            <motion.div initial={{ y: 50 }} animate={{ y: 0 }} className='flex items-center'>
              <TypeAnimation
                sequence={[
                  'Welcome to AI-Pro! How can I help you today?',
                  () => handleTypeAnimationComplete(),
                ]}
                wrapper="span"
                speed={80}
                cursor={false}
                style={{ fontSize: '0.8em', display: 'inline-block' }}
              />
            </motion.div>
            {showPoweredBy && (
              <div className='flex justify-center'>
                <motion.img
                  src="/assets/poweredby.png"
                  alt="powered by"
                  className="block dark:hidden h-7 w-auto invert"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                />
                <motion.img
                  src="/assets/poweredby.png"
                  alt="powered by"
                  className="hidden dark:block h-7 w-auto"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                />
              </div>
            )}
          </motion.div>
        )}
      </div>
      <div className="textchat-container no-gradient-sm fixed bottom-0 left-0 w-full sm:bg-gradient-to-b absolute">
        {conversationId !== 'new' || conversation.endpoint !== 'openAI' ? <OptionsBar /> : null}
        <div className="input-panel relative w-full border-t py-2 dark:border-white/20 dark:bg-[#1a1d22] md:border-t-0">
          <form className={`${messagesTree?.length ? 'shadow-outer-glow dark:shadow-outer-glow-dark' : ''} stretch z-[60] mx-auto flex w-full max-w-full flex-row gap-3 px-2.5 last:mb-2 lg:max-w-2xl lg:p-0 xl:max-w-3xl`}>
            <div className="relative flex h-full w-full flex-1 flex-col">
              <label
                htmlFor="text-input-odfigj"
                className={cn(
                  'rounded-xl',
                  'dark:border-gray-900/50 dark:text-white',
                  disabled ? 'bg-gray-100 dark:bg-gray-900' : 'bg-white dark:bg-gray-700',
                )}
              >
                {imagesPreview && imagesPreview.length > 0 && (
                  <div className={cn('flex flex-row gap-2 pl-2 pt-2  md:pl-4 md:pt-4')}>
                    {imagesPreview.map((image, index) => {
                      const id = image.id;
                      const isLoading = image.isLoading;
                      const uploaded = image.uploaded;

                      return (
                        <div
                          key={index}
                          className="group relative flex items-center justify-center rounded-xl"
                        >
                          {uploaded && (
                            <IoIosCloseCircleOutline
                              onClick={() => {
                                setImagesPreview(imagesPreview.filter((image) => image.id !== id));
                                setImageUrls(imageUrls.filter((image) => image.id !== id));
                              }}
                              title="delete image"
                              className="absolute -top-1 right-0 -mr-1 flex hidden cursor-pointer rounded-full border bg-red-500  text-white group-hover:flex"
                            />
                          )}
                          <img
                            key={id}
                            className={cn(
                              'h-16 rounded-[6px] shadow-md',
                              isLoading && 'opacity-30',
                            )}
                            src={image.base64}
                            alt={`Upload Preview ${index}`}
                          />
                          {isLoading && (
                            <AiOutlineLoading3Quarters className="absolute animate-spin" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                <div
                  className={cn(
                    'input-container bg-[#f5f5f5] dark:bg-[#22252A] relative flex w-full flex-grow flex-row rounded-xl py-[10px] pl-2 py-6 md:pl-4',
                  )}
                >
                  {/* <EndpointMenu /> */}
                  <Microphone />
                  {showFileUpload ? (
                    <FileUpload
                      onFileChange={(value) => {
                        setImagesPreview([...imagesPreview, ...value]);
                      }}
                      onUpload={(value) => {
                        setImageUrls([...imageUrls, ...value]);
                      }}
                    />
                  ) : null}
                  <TextareaAutosize
                    // set test id for e2e testing
                    id="text-input-odfigj"
                    data-testid="text-input"
                    tabIndex="0"
                    autoFocus
                    ref={inputRef}
                    // style={{maxHeight: '200px', height: '24px', overflowY: 'hidden'}}
                    rows="1"
                    value={disabled || isNotAppendable ? '' : text}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    onChange={changeHandler}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                    placeholder={getPlaceholderText()}
                    disabled={disabled || isNotAppendable}
                    className="m-0 ml-2 mr-14  md:mr-14 flex h-auto max-h-52 flex-1 resize-none overflow-auto border-0 bg-transparent p-0 pl-1 leading-6 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-0 focus-visible:ring-0 dark:bg-transparent placeholder:text-gray-200 dark:placeholder:text-gray-500 md:ml-2 outline-none"
                    aria-label="Text input"
                  />

                  <SubmitButton
                    submitMessage={submitMessage}
                    handleStopGenerating={handleStopGenerating}
                    disabled={disabled || isNotAppendable}
                    isSubmitting={isSubmitting}
                    isTyping={!!text || !!imagesPreview?.length || !!imageUrls?.length}
                    endpointsConfig={endpointsConfig}
                    endpoint={conversation?.endpoint}
                  />
                </div>
              </label>
            </div>
          </form>
          <Footer />
        </div>
      </div>
    </>
  );
}
