import React, { useState, useEffect, useRef } from 'react';
import { IoIosLock } from 'react-icons/io';
import { MdOutlineArrowDropDown, MdOutlineArrowRight } from 'react-icons/md';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { usePopup, usePricingPopup } from '~/components/helper/store';
import { cookie } from '~/utils';
import global from '~/utils/global';
import { setCookieValue } from '~/components/helper/global';
import { useSetOptions } from '~/hooks';
const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: (index) => ({
    y: 0,
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 0.3,
      delay: index * 0.1,
    },
  }),
};

const ChatModels = ({ models, activeModel, handleOptionClick }) => {
  const { setOption } = useSetOptions();
  const pState = usePricingPopup((state) => state);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [truncated, setTruncated] = useState({});
  const userPlan = AIPRO_USER.subscription_type || {};
  const sState = usePopup((state) => state);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchmove', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchmove', handleClickOutside);
    };
  }, []);

  const checkTruncation = () => {
    models.forEach((model, index) => {
      const element = document.getElementById(`model-title-${index}`);
      if (element && element.scrollWidth > element.clientWidth) {
        setTruncated((prevState) => ({ ...prevState, [index]: true }));
      } else {
        setTruncated((prevState) => ({ ...prevState, [index]: false }));
      }
    });
  };

  useEffect(() => {
    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [models]);

  useEffect(() => {
    if (activeModel) {
      // console.log("activeModel:", activeModel)
      const setModel = setOption('model');
      setModel(activeModel);
    }
  }, []);

  const handleModelClick = (model) => {
    const models = model.title || {};
    // console.log("models", models)

    if (model.isDisabled) {
      // GUEST - REGISTERED UNPAID
      if ((models == 'dallE' || models == 'claude' || models == 'Llama' || models == 'gemini' || models == 'Opensource') && (!userPlan)) {
        if (
          cookie.getValue('flow') === 'chatapp' &&
          cookie.getValue('n_flow') === 'chatapp' &&
          !cookie.getValue('user_plan')
        ) {
          if (window.mixpanel) {
            window.mixpanel.track("modal-btn-chatpro-chatapp", {
              "mixPanelTrack": models,
              "mixPanelClickLocation": 'modal-btn-chatpro-chatapp',
            });
          }
        } else {
          sState.setMixPanelTrack(models);
          sState.setMixPanelClickLocation('modal-btn-chatpro-modal');
          setCookieValue('mixPanelTrack', models, '.ai-pro.org');
          setCookieValue('mixPanelClickLocation', 'modal-btn-chatpro-modal', '.ai-pro.org');  
        }
        global.showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      // PRO
      } else if ((models == 'GPT-4' || models == 'LLaMA-2 Chat (70B)') && (userPlan == 'PRO')) {
        if (window.mixpanel) {
          window.mixpanel.track("modal-tabs-chatpro-redirect", {
            "mixPanelTrack": models,
            "mixPanelClickLocation": 'modal-tabs-chatpro-redirect',
          });
        }
        const ppg = cookie.getValue('ppg') || 59;
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
      // BASIC with specific models
      } else if ((models == 'LLaMA-3 Chat (70B)' || models == 'GPT-4' || models == 'LLaMA-2 Chat (70B)') && (userPlan == 'BASIC')) {
        if (window.mixpanel) {
            window.mixpanel.track("modal-tabs-chatpro-redirect", {
                "mixPanelTrack": models,
                "mixPanelClickLocation": 'modal-tabs-chatpro-redirect',
            });
        }
        const ppg = cookie.getValue('ppg') || 59;
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
      // Default
      } else {
        if (
          cookie.getValue('flow') === 'chatapp' &&
          cookie.getValue('n_flow') === 'chatapp' &&
          !cookie.getValue('user_plan')
        ) {
          if (window.mixpanel) {
            window.mixpanel.track("modal-btn-chatpro-chatapp", {
              "mixPanelTrack": models,
              "mixPanelClickLocation": 'modal-btn-chatpro-chatapp',
            });
          }
        } else {
          sState.setMixPanelTrack(models);
          sState.setMixPanelClickLocation('modal-btn-chatpro-modal');
          setCookieValue('mixPanelTrack', models, '.ai-pro.org');
          setCookieValue('mixPanelClickLocation', 'modal-btn-chatpro-modal', '.ai-pro.org');  
        }
        global.showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      }
    } else {
      handleOptionClick(model);
    }
    setIsDropdownOpen(false);
  };

  return isSmallScreen ? (
    <>
    <div className="relative">
      <button
        className="py-3 px-4 rounded-lg w-full text-left mb-1 bg-gradient-to-b from-blue-top to-blue-bottom text-white shadow-md"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {activeModel ? models.find(model => model.model === activeModel)?.title || 'Select Model' : 'Select Model'}
        {isDropdownOpen ? (
          <MdOutlineArrowDropDown className='inline float-right text-xl' />
        ) : (
          <MdOutlineArrowRight className='inline float-right text-xl' />
        )}
      </button>
      {isDropdownOpen && (
        <ul className="chatmodels absolute bg-white dark:bg-[#232d39] border border-gray-300 dark:border-gray-800 rounded-md shadow-lg w-full z-10" ref={dropdownRef}>
          {models.map((model, index) => (
            <li
              key={index}
              onClick={() => handleModelClick(model)}
              className={cn(
                'py-2 px-4 cursor-pointer hover:bg-blue-200 text-dark dark:text-white font-bold',
                activeModel === model.model && !(model.isDisabled) ? 'bg-blue-100 dark:bg-[#324157]' : ''
              )}
            >
              {model.title}
              {model.isDisabled && (
                <IoIosLock className='ml-[2px] text=[20px] text-gray-500 inline-block' />
              )}
              {model.isBeta && (
                <span className="py-0.25 mx-[2px] rounded bg-blue-200 px-1 py-[2px] text-[10px] text-[#4559A4] transform -skew-x-12">
                  Beta
                </span>
              )}
              {model.isNew && (
                <span className="py-1 ml-[6px]  mx-[2px] rounded bg-green-500 px-1 text-[10px] text-white transform -skew-x-12">
                  New
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  </>
  ) : (
    <motion.div initial="hidden" animate="visible">
      {models.map((model, index) => (
        <motion.button
          key={index}
          type="button"
          title={model.title}
          className={cn(
            'py-3 pl-3 pr-2 rounded-lg w-full text-left mb-[6px] pb-[6px] font-bold overflow-hidden max-w-[250px] md:max-w-[320px] lg:max-w-[250px] truncate',
            activeModel === model.model && !model.isDisabled
              ? 'bg-gradient-to-b from-blue-top to-blue-bottom text-white shadow-md'
              : 'bg-[#F5F5F5] hover:bg-[#efefef] dark:bg-[#2A2E30] dark:hover:bg-[#323537] text-gray-900 dark:text-gray-200'
          )}
          onClick={() => handleModelClick(model)}
          tabIndex={0}
          aria-label={model.title}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleModelClick(model);
            }
          }}
          variants={itemVariants}
          custom={index}
        >
          {activeModel === model.model && !model.isDisabled && (
            <MdOutlineArrowRight className="float-right block md:hidden" />
          )}
          <div className='overflow-hidden inline-block pr-[1px] mr-[4px]'>
            <motion.div
              id={`model-title-${index}`}
              className={cn('inline-block',
                !model.isNew || !model.isNew ? '' : 'max-w-[150px] max-w-1024:max-w-[160px] min-w-1281:max-w-[150px]'
              )}
              title={model.title}
              whileHover={truncated[index] ? { x: [0, -70] } : {}}
              initial={{ x: 0 }}
              animate={{ x: 0 }}
              transition={{ duration: 1.2, ease: 'linear' }}
            >
              {model.title}
            </motion.div>
          </div>
          {model.isDisabled && (
            <IoIosLock className="ml-1 mb-1 mt-[1px] text-[16px] inline-block align-top text-gray-500" />
          )}
          {model.isBeta && (
            <span className="align-top ml-[2px] rounded-sm bg-blue-200 px-[2px] text-[10px] py-[2px] text-[#4559A4] transform -skew-x-12">
              Beta
            </span>
          )}
          {model.isNew && ( 
            <span className="align-top ml-[2px] rounded bg-green-500 px-1 text-[10px] py-1 text-white transform -skew-x-12">
              
              New
            </span>
          )}
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 0, y: -10 }}
            whileHover={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute left-0 top-full mt-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 w-full"
          >
          </motion.div>
        </motion.button>
      ))}
    </motion.div>
  );
};

export default ChatModels;
